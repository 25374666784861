<template>
  <a-modal v-model="visible" :title="option.title" @ok="handleOk">
    <a-input v-model="value" :placeholder="option.placeholder"></a-input>
  </a-modal>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        option: {},
        value: ""
      }
    },
    methods: {
      show(option) {
        this.option = option
        this.value = option.value
        this.visible = true
      },
      handleOk() {
        this.visible = false
        this.option.onOk(this.value)

      }
    }
  }
</script>

<style scoped>

</style>
