<template>
  <div class="search-container">

    <div class="search-action">
      <slot></slot>
    </div>

    <div class="search-bar">

      <a-input class="input" :placeholder="placeholder" v-model="keyword" @pressEnter="search" allowClear @change="onSearchChange"></a-input>
      <a-button class="btn" type="primary" @click="search">查询</a-button>
      <a-button class="btn" @click="reset">重置</a-button>
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      placeholder: {
        default: '请输入关键字搜索'
      },
    },
    data () {
      return {
        keyword: ''
      }
    },
    methods: {
      search () {
        this.$emit('search', this.keyword)
      },
      reset () {
        this.keyword = ''
        this.$emit('reset')
      },
      onSearchChange(){
        if(!this.keyword){
          this.search()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .search-container {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;

    .search-bar {
      min-width: 125px;
      flex-direction: row;
      justify-content: flex-end;
      display: flex;
      align-items: center;

      .btn {
        margin-left: 0.5rem;
      }

      .input {
        width:14rem;
        margin-left: 0.5rem;
      }
    }

    .search-action{
      flex: 1;
      *{
        margin-right: 0.2rem;
        vertical-align: middle;
      }
    }
  }
</style>
