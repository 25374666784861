<template>
  <a-input :disabled="!modifyPassword && edit" :value="value" @change="valueChange">
    <a-checkbox v-if="edit" v-model="modifyPassword" slot="addonAfter">修改密码</a-checkbox>
  </a-input>
</template>

<script>
    export default {
        props:["value","edit"],
      data(){
          return {
            modifyPassword:false
          }
      },
      methods:{
        valueChange(e){
          console.log(e.target.value)
          this.$emit("input",e.target.value)
        }
      }
    }
</script>

<style scoped>

</style>
