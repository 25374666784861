<template>
    <div class="container">

        <template v-if="res.type == 'image'">
            <img class="image clickable" :src="res.url" @click="clickPlay"/>
        </template>

        <template v-else>
            <img class="image" :src="res.cover"/>
            <a-icon class="btn-play" :width="50" :height="50" type="play-circle" color="green" @click.stop.prevent="clickPlay" />
        </template>

    </div>
</template>

<script>
    export default {
        props:["res"],
        methods:{
            clickPlay(){
                window.open(this.res.url)
            }
        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        max-width: 240px;
        position: relative;
    }

    .image{
        width: 100%;
        max-height: 100%;
    }

    .btn-play{
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        background: rgba(255,255,255,0.8);
        transform: translate(-50%,-50%);
        padding: 3px;
        box-shadow: 0 0 5px #999;
    }

    .btn-play:hover{

        opacity: 0.8;
    }

</style>
