<template>
  <div class="item">
    <a-upload
      name="file"
      class="image-upload"
      listType="picture-card"
      :showUploadList="false"
      :beforeUpload="beforeUpload"
      :action="uploadUrl"
      @change="handleChange"
    >

      <a-button v-if="value" icon="play-circle"  :size="size" @click="clickVideo"></a-button>

      <div v-else>
        <a-icon :type="loading ? 'loading' : 'plus'"/>
        <div class="ant-upload-text">上传视频</div>
      </div>
    </a-upload>

    <div class="image-hint" v-if="hint">
      <a-tag class="image-hint" color="green">{{hint}}</a-tag>
    </div>


  </div>
</template>

<script>
  import AUpload from 'ant-design-vue/es/upload/Upload'
  import config from '../../config/config'

  export default {
    components: {
      AUpload
    },
    props: {
      value: String,
      hint: String
    },
    data () {
      return {
        uploadUrl: config.apiServer + 'app/upload',
        loading: false
      }
    },
    methods: {
      beforeUpload (file) {
        return true

        const isImage = file.type.startsWith('image')
        if (!isImage) {
          this.$message.error('请选择图片类型的文件!')
        }
        const isLt1M = file.size / 1024 / 1024 < 1
        if (!isLt1M) {
          this.$message.error('图片大小不能超过 1MB!')
        }
        return isImage && isLt1M
      },
      customRequest (data) {
        console.log('customRequest')
        console.log(data)
      },
      handleChange (info) {
        console.log('执行子组件')
        console.log(info)
        if (info.file.status === 'uploading') {
          this.loading = true
          return
        }

        if (info.file.status === 'done') {
          this.loading = false
          this.value = info.file.response.data
          this.$emit('input', this.value)
          return
        }
      },
      clickVideo(){
        window.open(this.value)
      }
    }
  }
</script>

<style scoped>
  .item {
    display: flex;

  }

  .image-upload {
    width: auto;
    max-width: 240px;
  }

  .image-hint {
    margin-left: 15px;
  }

</style>
