/**
 vue-quill-editor github地址：https://github.com/surmon-china/vue-quill-editor
 官方文档地址：https://www.npmjs.com/package/vue-quill-editor

 参考文档：https://www.jianshu.com/p/a6cba69d6e49
 **/

import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
