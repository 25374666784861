<template>
  <div class="item">
    <a-upload
      name="file"
      :showUploadList="false"
      :beforeUpload="beforeUpload">
      <a-button>导入CSV</a-button>
    </a-upload>


  </div>
</template>

<script>

  import Papa from "papaparse";

  export default {
    props: {
      value: String,
      hint: String
    },
    data () {
      return {
        loading: false
      }
    },
    methods: {
      beforeUpload (file) {
        this.importCsv(file)
        return false
      },
      importCsv(file){
        let self = this
        let selectedFile = null
        selectedFile = file
        if (selectedFile === undefined){
          return
        }
        var reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = evt => {
          // 检查编码
          // let encoding = this.checkEncoding(evt.target.result);
          // 将csv转换成二维数组
          Papa.parse(selectedFile, {
            encoding:"ANSI",
            complete: res => {
              // UTF8 \r\n与\n混用时有可能会出问题
              let data = res.data;
              if (data[data.length - 1] == "") {
                //去除最后的空行
                data.pop();
              }
              console.log(data);  // data就是文件里面的数据

              self.$emit('onImport',data)

            }
          });
        };
      }
    }
  }
</script>

<style scoped>
  .item {
    display: flex;
  }


</style>
