/**
 * Frequently-used state/getters
 */
const getters = {
  // settings
  sidebar: state => state.setting.sidebar

}

export default getters
