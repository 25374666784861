<template>

  <div>

    <a-input-search placeholder="选择地址" :value="value?value.address:''" readOnly @search="showModal">
      <a-icon type="environment" slot="addonBefore" ></a-icon>
      <a-icon v-if="value&&value.address" slot="suffix" type="close-circle" @click="clickClear"/>
      <a-button slot="enterButton" @click="showModal">选择地址</a-button>
    </a-input-search>

    <a-modal v-model="visible" title="选择地址" centered :width="700" @ok="handleOk">
      <a-input id="search" v-model="model.address"></a-input>
      <div class="amap-page-container" style="margin-top: 10px">
        <el-amap
          vid="amap"
          :zoom="zoom"
          class="amap-demo"
          :center="center"
          :amapManager="amapManager"
          :events="events" >
        </el-amap>
      </div>
    </a-modal>

  </div>


</template>

<script>
  import {AMapManager} from "vue-amap"
  let amapManager = new AMapManager();

  export default {
    props: {
      value:{
        type:Object,
        default:()=>{
          return {
            address:"",
            latitude: null,
            longitude: null,
          }
        }
      }
    },
    data() {
      let vm = this
      return {
        amapManager,
        model:{
          address: "",
          latitude: null,
          longitude: null,
        },
        visible: false,
        zoom: 14,
        center: [113.325, 23.1064],
        result: [],
        radius: 10,
        map: null,
        hasInit:false,
        events: {
          init(o) {
            vm.map = o;
            console.log('map init')
            vm.startDrag();
            vm.startSearch()
            //方法一
            /*AMapUI.loadUI(['misc/PositionPicker'], function(PositionPicker) {
              var positionPicker = new PositionPicker({
                  mode: 'dragMap',
                  map: o
              });
              positionPicker.on('success', function(positionResult){
                vm.result=positionResult.regeocode.pois;
                vm.address=positionResult.regeocode.formattedAddress;
                vm.center=[positionResult.position.lng,positionResult.position.lat]
              })
              positionPicker.start();
            })*/
          }
        }
      }
    },

    watch: {
      map: function () {
        if (this.map != null) {
          // this.startDrag();
        }
      }
    },

    mounted() {
      // this.startSearch()
    },

    methods: {

      startDrag() {//方法二
        let vm = this;
        let map = this.amapManager.getMap();
        AMapUI.loadUI(['misc/PositionPicker'], function (PositionPicker) {
          var positionPicker = new PositionPicker({
            mode: 'dragMap',
            map: map
          });
          //定位
          /*let geolocation;
          map.plugin('AMap.Geolocation', function () {
            geolocation=new AMap.Geolocation({
              showButton: true,        //显示定位按钮，默认：true
              showMarker: false,        //定位成功后在定位到的位置显示点标记，默认：true
              extensions:'all'
            })
            map.addControl(geolocation);
            geolocation.getCurrentPosition();
            AMap.event.addListener(geolocation, 'complete', function(data){
              //positionPicker.start();
            });//返回定位信息
          })*/
          positionPicker.on('success', function (positionResult) {
            console.log(positionResult)
            vm.result = positionResult.regeocode.pois;
            vm.model.latitude = positionResult.position.lat;
            vm.model.longitude = positionResult.position.lng;
            vm.model.address = positionResult.regeocode.formattedAddress
            //vm.center=[positionResult.position.lng,positionResult.position.lat]
            if(!vm.hasInit){
              vm.showModal()
              vm.hasInit=true
            }
          })
          //positionPicker.on('fail', function(failResult){
          //  console.log(failResult)
          //})
          positionPicker.start();
        })
      },
      startSearch() {
        let vm = this;
        AMapUI.loadUI(['misc/PoiPicker'], function (PoiPicker) {
          var poiPicker = new PoiPicker({
            input: 'search', //输入框id
          });
          //监听poi选中信息
          poiPicker.on('poiPicked', function (poiResult) {
            //用户选中的poi点信息
            console.log(poiResult)
            vm.center = [poiResult.item.location.lng, poiResult.item.location.lat]
          });
        });
      },

      getResult() {
        return {
          // result:this.result,
          address: this.address,
          longitude: this.longitude,
          latitude: this.latitude,
        }
      },
      showModal(){
        this.visible=true
        if(this.value.address){
          this.center = [this.value.longitude,this.value.latitude]
        }
        setTimeout(()=>{
          this.model.address = this.value.address+""
        },200)
      },
      clickClear(){
        this.$emit("input",{})
      },
      handleOk(){
        this.$emit("input",JSON.parse(JSON.stringify(this.model)))
        this.visible=false
      }
    }
  }
</script>

<style scoped>
  .amap-page-container {
    height: 400px;
    position: relative;
    width: 100%;
    border: 1px solid #CCC;
  }

  .search-input {
    position: absolute;
    z-index: 5;
    width: 94%;
    top: 10px;
    left: 3%;
    opacity: 0.9;
    padding-left: 20px;
    border-radius: 5px;
    height: 44px;
    line-height: 44px;
    border: 1px solid #CCC;
  }

  .search-input:focus, .search-input:hover {
    opacity: 1;
    border: none;
    outline: none;
    box-shadow: 2px 2px 10px #333;
  }

</style>

