<template>
  <a-tree
    ref="menuTree"
    :checkable="true"
    :blockNode="true"
    :defaultExpandAll="false"
    :checkStrictly="false"
    :treeData="treeData"
    @check="handleCheck"
    :checkedKeys="value"
    ></a-tree>
</template>

<script>
  export default {
    props:{
      treeData: {
        type:Array,
        default:[]
      },
      value: {
        type:Array,
        default:[]
      },
    },
    methods:{
      handleCheck (checkedKeys) {
        this.$emit("input",checkedKeys)
      },
    }
  }
</script>

<style scoped>

</style>
