let db = {
  save (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get (key, defaultValue = {}) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key)) || defaultValue
    }
    return defaultValue
  },
  remove (key) {
    localStorage.removeItem(key)
  },
  clear () {
    localStorage.clear()
  },

  checkVersion () {
    // var version = "1.0.0.20191204-1"
    // console.log(localStorage.getItem("version"))
    // if(localStorage.getItem("version") != version){
    //   localStorage.clear()
    //   localStorage.setItem("version",version)
    // }
  }
}

db.checkVersion()

export default db
