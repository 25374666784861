<template>
  <quill-editor
    :value="value"
    ref="myQuillEditor"
    :options="editorOption"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @change="onEditorChange($event)">
  </quill-editor>
</template>
<script>

  import {quillRedefine} from 'vue-quill-editor-upload'
  import {quillEditor, Quill} from 'vue-quill-editor'
  import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
  import config from "../../config/config";

  Quill.register('modules/ImageExtend', ImageExtend)

  export default {
    components:{
      quillEditor,quillRedefine
    },
    data () {
      return {
        editor: null,
        editorOption: {
          placeholder:"请输入内容",
          modules: {
            ImageExtend: {
              loading: true,
              name: 'file',
              action: this.uploadUrl,
              response: (res) => {
                return res.data
              }
            },
            toolbar: {
              container: container,
              handlers: {
                'image': function () {
                  QuillWatch.emit(this.quill.id)
                }
              }
            }
          }
        }
      };
    },
    computed:{
      uploadUrl(){
        return config.apiServer + 'app/upload'
      }
    },
    props: ['value'],
    created() {
      this.editorOption = quillRedefine({
        ...this.editorOption,
        uploadConfig: {
          action: this.uploadUrl,  // 必填参数 图片上传地址
          // 必选参数  res是一个函数，函数接收的response为上传成功时服务器返回的数据
          // 你必须把返回的数据中所包含的图片地址 return 回去
          res: (response) => {
            console.log(response)
            return response.data
          },
          name: 'file'  // 图片上传参数名
        }
      })
    },
    mounted() {
      //  自定义粘贴图片功能
      let quill = this.$refs.myQuillEditor.quill
      // if (!this.disableFlag) {
      //   toolbarOptions[0] = ['image']
      // }
      this.$forceUpdate()
      quill.root.addEventListener('paste', evt => {
        if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
          evt.preventDefault();
          [].forEach.call(evt.clipboardData.files, file => {
            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
              return
            }
            this.uploadToServer(file, (res) => {
              // 获取光标内容
              var range = quill.getSelection()
              if (range) {
                this.uploadAttachment(res, file, null)
                //  将光标移动到图片后面
                this.$refs.myQuillEditor.quill.setSelection(range.index + 1)
              }
            })
          })
        }
      }, false)
    },

    methods:{
      onEditorReady(editor) { // 准备编辑器
      },
      onEditorBlur(){}, // 失去焦点事件
      onEditorFocus(){}, // 获得焦点事件
      onEditorChange(event){
        this.$emit("input",event.html)
      },
      uploadToServer(file, callback) {
        let formData = new FormData()
        formData.append('file', file)
        this.$upload(this.uploadUrl,formData).then(suc=>{
          console.log("图片上传成功")
          console.log(suc)
          callback(suc)
        })

        // var xhr = new XMLHttpRequest()
        // var formData = new FormData()
        // formData.append('content', file)
        // xhr.open('post', '服务器路径')
        // xhr.withCredentials = true
        // xhr.responseType = 'json'
        // xhr.send(formData)
        // xhr.onreadystatechange = () => {
        //   if (xhr.readyState === 4 && xhr.status === 200) {
        //     callback(xhr.response)
        //   }
        // }
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制一次性上传最多 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
      },
      handleError(err, file, fileList) {
        this.$message.warning(`文件上传失败，请尝试重新上传，文件失败原因为： ${err}`)
      },
      uploadAttachment(response, file, fileList) {
        let quill = this.$refs.myQuillEditor.quill
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片  res.info为服务器返回的图片地址
        quill.insertEmbed(length, 'image', response.data)
        quill.setSelection(length + 1)

        return

        let fileType = null
        if (file.raw && file.raw.type) {
          fileType = file.raw.type
        } else {
          fileType = file.type
        }


        let params = {}
        params = {
          // 保存文件上传的参数
        }
        workService.create(params).then((res) => {
          this.$message.success('上传成功')
        }).catch((err) => {
          this.$message.error(err)
        })
      },
    }
  }
</script>
<style>
  .ql-editor{
    min-height: 400px;
    background: #FCFCFC;
  }

  p{
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    white-space: normal;
  }

</style>
