<template>

  <a-button
          style="margin-right: 4px"
    size="small"
    icon="delete"
    type="danger"
    @click="$emit('click')"
    ghost ></a-button>

</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>
