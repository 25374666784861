<template>

  <a-drawer
    :title="title"
    :maskClosable="true"
    width=650
    placement="right"
    :closable="true"
    @close="hide"
    :visible="visible" >

    <a-form-model
      ref="ruleForm"
      v-bind="formItemLayout"
      layout="horizontal"
      :rules="rules"
      :model="model" >

      <slot></slot>

    </a-form-model>



    <div class="drawer-bottom-button">
      <slot name="action"></slot>
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>

  </a-drawer>


</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      rules:{
        type: Object,
        default: ()=>{}
      },
      model:{
        type: Object,
        default: ()=>{}
      }
    },
    data () {
      return {
        formItemLayout:{
          labelCol: { span: 5 },
          wrapperCol: { span: 17 },
        },
        visible:false
      }
    },
    methods: {
      show(){
        this.visible=true
      },
      hide(){
        this.visible=false
      },
      handleSubmit(){
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit("handleSubmit")
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .drawer-bottom-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 2px 2px;
    z-index: 10;
  }
</style>
