<template>
  <div class="item">

    <a-upload
      name="file"
      class="image-upload"
      listType="picture-card"
      :file-list="fileList"
      :beforeUpload="beforeUpload"
      :action="uploadUrl"
      @change="handleChange"
    >
      <div v-if="fileList.length < 8">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传
        </div>
      </div>

    </a-upload>

  </div>
</template>

<script>
  import AUpload from 'ant-design-vue/es/upload/Upload'
  import config from '../../config/config'

  export default {
    components: {
      AUpload
    },
    props: {
      value: Array,
      hint: String
    },
    data () {
      return {
        uploadUrl: config.apiServer + 'app/upload',
        loading: false,
        fileList: [],
      }
    },

    methods: {
      setValue(res){
        this.fileList=[]
        res.forEach(item=>{
          this.fileList.push({
            uid: item.url,
            name: item.url,
            status: 'done',
            url:item.url,
            type:item.type,
            cover:item.cover
          })
        })
      },
      beforeUpload (file) {
        const isImage = file.type.startsWith('image')
        if (isImage) {
          this.uploadUrl = config.apiServer + 'app/upload'
        }

        const isVideo = file.type.startsWith('video')
        if(isVideo){
          this.uploadUrl = config.apiServer + 'app/upload/video'
        }

        // const isLt1M = file.size / 1024 / 1024 < 1
        // if (!isLt1M) {
        //   this.$message.error('图片大小不能超过 1MB!')
        // }
        return true
      },

      handleChange (info) {

        console.log('执行子组件')
        console.log(info)
        if (info.file.status === 'uploading') {
          this.loading = true
          this.fileList = info.fileList;
          return
        }

        if (info.file.status === 'done') {
          this.loading = false
          let res = info.file.response.data
          if(res.cover){
            //视频
            this.fileList[this.fileList.length-1] = {
              uid: res.url,
              name: res.url,
              status: 'done',
              url:res.url,
              type:"video",
              cover:res.cover
            }
          }else{
            //图片
            this.fileList[this.fileList.length-1] = {
              uid: res,
              name: res,
              status: 'done',
              url:res,
              type:"image"
            }
          }

          this.emitValueChange()
          return
        }

        if(info.file.status === 'removed'){
          this.fileList = info.fileList;
          this.emitValueChange()
        }

      },
      emitValueChange(){
        let res = []
        this.fileList.forEach(item=>{
          res.push({
            type:item.type,
            cover:item.cover,
            url:item.url
          })
        })
        this.$emit('input', res)
      }
    }
  }
</script>

<style scoped>
  .item {
    display: flex;

  }

  .image-upload {
  }

  .image-hint {
    margin-left: 15px;
  }

</style>
