<template>
<!--  <a-icon-->
<!--    type="eye"-->
<!--    theme="twoTone"-->
<!--    twoToneColor="#4a9ff5"-->
<!--    title="查看"-->
<!--    @click="$emit('click')"-->
<!--  ></a-icon>-->

  <a-button
          style="margin-right: 4px"
    size="small"
    icon="mail"
    type="primary"
    @click="$emit('click')"
    ghost ></a-button>

</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>
